<template>
	<div>
		<div class="header">
			<div class="top top_mafi f-left">
				<div class="util">
					<button
						@click="$emit('goBack')"
						class="arrow_white"
					>
						<i class="icon-arrow-left"></i>
					</button>
				</div>
				<div class="page_tit_area">
					<h2 class="page_tit">{{  program.title }}</h2>
					<span class="page_subtit hide">{{ item_cartel.cartl_name }}</span>
				</div>
			</div>
		</div>
		<!-- //고정해더 -->
		<div class="section_wrap pt-50">
			<!-- 커버 -->
			<div class="cartel_cover">
				<div class="cover_background">
					<img
						v-if="item_cartel.cartl_img_url"
						:src="$request.upload_url(item_cartel.cartl_img_url)" alt="등록커버이미지"
					>
				</div>
				<div class="container cover_content cover_content_set clear">
					<div class="title_area">
						<h2>{{ item_cartel.cartl_name}}</h2>
					</div>
					<div class="cartel_area clear">
						<div class="badge_area">
                <span class="badge_30 " :class="'badge_30_' + item_cartel.type + '_bl'">
                  <em class="hide">{{  item_cartel.type  }}</em>
                </span>
						</div>
						<div class="text_area">
							<div class="level">{{  item_cartel.cartl_level_name }}</div>
							<div class="member">
                {{ $language.common.memberCount }}<b>{{  item_cartel.current_member_count }}</b>
							</div>
						</div>
					</div>
				</div>
			</div>
			<!-- //커버 -->
			<!-- 카르텔안내 -->
			<div class="ct_introduce">
				<div class="container">
					<div class="row">
						<h3 class="hide">{{ $language.common.cartel_intro }}</h3>
						<p class="introduce_desc">
              {{ $language.cartel.joinAfterWithdrawalCartel }}
						</p>
					</div>
				</div>
			</div>
			<!-- //카르텔안내 -->
			<!-- 하단고정 탈퇴하기 -->
			<div class="bottom_btn">
				<div class="btn_container">
					<div class="check_center">
						<div class="bright_checks">
							<input
								v-model="is_out"
								type="checkbox" id="resign"
							>
							<label for="resign">{{ $language.cartel.agreeCartel }}</label>
						</div>
					</div>
					<div class="btn_area bg_w">
						<button
							@click="onPin('check')"
							class="btn_l btn_fill_blue"
							:disabled="!is_out"
						>{{ $language.common.withdrawal }}</button>
					</div>
					<p class="copyright pt-20">Copyright © MAFI Inc.</p>
				</div>
			</div>
			<!-- //하단고정 탈퇴하기 -->
		</div>
	</div>
</template>

<script>

	export default {
		name: 'mafia083'
		, components: {}
		, props: ['user']
		, data: function(){
			return {
				program: {
					name: this.$language.common.withdrawal_cartel
					, title: this.$language.common.withdrawal_cartel
					, type: 'cartel_sub'
					, not_header: true
					, not_footer: true
				}
				, item_cartel: {

				}
				,is_pin: false
				,pin_option: {
					pin_type: 'check'
				}
				,is_out: false
			}
		}
		, methods: {

			getData: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.get_cartel_info
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
							, notice_mttrs_list_page: { page_number: 1, pagerecnum: 10}
							, post_list_page: { page_number: 1, pagerecnum: 10}
						}
						, type: true
					})

					if(result.success){
						this.item_cartel = result.data

						switch (this.item_cartel.cartl_concern_sphere_code){
							case 'CM00400001': this.$set(this.item_cartel, 'type', 'music'); break
							case 'CM00400002': this.$set(this.item_cartel, 'type', 'art') ; break
							case 'CM00400003': this.$set(this.item_cartel, 'type', 'fashion'); break
							case 'CM00400004': this.$set(this.item_cartel, 'type', 'influence'); break
						}
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			,postCartelOut: async function(){
				try{
					this.$bus.$emit('on', true)
					const result = await this.$Request({
						method: 'post'
						, url: this.$api_url.api_path.post_cartel_out
						, data: {
							member_number: this.user.member_number
							, cartl_number: this.$route.params.idx
						}
						, type: true
					})

					if(result.success){
						this.$emit('to', { name: 'main'})
					}else{
						throw result.message
					}
				}catch (e) {
					console.log(e)
					this.$bus.$emit('notify', { type: 'error', message: e})
				}finally {
					this.$bus.$emit('on', false)
				}
			}
			, onPin: function (){
				this.$bus.$emit('onPin', 'check')
			}
		}
		, created() {
			this.$bus.$emit('onLoad', this.program)
			this.getData()

			this.$bus.$on('pinCallback', ()=> {
				this.postCartelOut()
			})
		}
	}
</script>